import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { Button } from '../Button';

const ProductCard = ({ cardName, ctaText, ctaLink, image, subheaderText }) => {
  return (
    <div className="product-card">
      <Image
        className="product-card__image"
        fluid={{ ...image?.fluid, aspectRatio: 1.76 }}
      />
      <h5 className="product-card__title">{cardName}</h5>
      <p className="product-card__subheader">{subheaderText}</p>
      <Button href={ctaLink} className="product-card__button" theme="dark">
        {ctaText}
      </Button>
    </div>
  );
};

ProductCard.propTypes = {
  cardName: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  image: PropTypes.object,
  subheaderText: PropTypes.string,
};

export default ProductCard;
