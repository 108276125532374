const validDeviceSlugs = ['laptop', 'tablet', 'mobile'];

export const deviceTypeFromDeviceAsset = (deviceAsset) => {
  // contentful returns an object that may or may not include a key that follows a [deviceSlug]Asset pattern.
  return Object.keys(deviceAsset).reduce((acc, cv) => {
    const filtered = validDeviceSlugs.filter((slug) => cv.includes(slug));
    if (filtered.length) acc = filtered[0];
    return acc;
  }, '');
};

export const deviceMediaFromDeviceAsset = (deviceAsset) => {
  const deviceKey = Object.keys(deviceAsset).filter((key) => {
    // find key that contains of the contains one of the valid device slugs
    return validDeviceSlugs.filter((slug) => key.includes(slug)).length === 1;
  });

  const fluid = deviceAsset[deviceKey]?.fluid;
  const mediaUrl = deviceAsset[deviceKey]?.file?.url;
  const contentType = deviceAsset[deviceKey]?.file?.contentType;
  return { ...deviceAsset[deviceKey] }
  // return { mediaUrl: mediaUrl, mediaType: contentType, fluid: fluid };
};

export const deviceDataFromDeviceAsset = (deviceAsset) => {
  // Pass this a device asset from contentful and it'll do the rest.
  const deviceType = deviceTypeFromDeviceAsset(deviceAsset);
  const asset = deviceMediaFromDeviceAsset(deviceAsset);

  return {
    asset: asset,
    deviceType: deviceType,
    fallbackImage: deviceAsset.fallbackImage
  };
};
