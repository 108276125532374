import React, { useEffect, useRef, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { getContainerSize, isMobile } from '../../utils';
import CarouselArrow from '../../../assets/svg/carouselArrow.svg';
import ProductCard from '../ProductCard/ProductCard';

const ProductsCarousel = ({ items }) => {
  const containerSize = getContainerSize();
  const mobile = isMobile();
  const slideWidth = 360;
  const containerRef = useRef();
  const [visibleSlides, setVisibleSlides] = useState();
  const hasScroll = visibleSlides < items.length;

  const carouselOptions = {
    totalSlides: items.length,
    infinite: true,
    visibleSlides,
    isIntrinsicHeight: true,
    dragEnabled: false,
  };

  useEffect(() => {
    if (!containerSize.width) {
      return;
    }

    if (mobile) {
      setVisibleSlides(1);
      return;
    }

    const containerElement = containerRef.current;
    const containerStyle = getComputedStyle(containerElement);
    const containerWidth =
      containerElement.offsetWidth -
      parseInt(containerStyle.paddingLeft) -
      parseInt(containerStyle.paddingRight);
    setVisibleSlides(Math.floor(containerWidth / slideWidth));
  }, [containerSize, mobile]);

  return (
    <div className="products-carousel">
      <div
        className="container products-carousel__container"
        ref={containerRef}
      >
        {carouselOptions && (
          <CarouselProvider
            {...carouselOptions}
            className={`products-carousel__carousel ${
              hasScroll ? '' : 'products-carousel__carousel--no-scroll'
            }`}
          >
            <Slider>
              {items.map((item, i) => (
                <Slide index={i} key={`products-carousel__item${i}`}>
                  <div
                    className="products-carousel__item"
                    style={{ width: mobile ? '100%' : slideWidth }}
                  >
                    <ProductCard {...item} />
                  </div>
                </Slide>
              ))}
            </Slider>
            {hasScroll && (
              <>
                <ButtonBack className="products-carousel__button products-carousel__button--prev">
                  <CarouselArrow className="products-carousel__arrow products-carousel__arrow--prev" />
                </ButtonBack>
                <ButtonNext className="products-carousel__button products-carousel__button--next">
                  <CarouselArrow className="products-carousel__arrow products-carousel__arrow--next" />
                </ButtonNext>
              </>
            )}
            <DotGroup className="products-carousel__dots" />
          </CarouselProvider>
        )}
      </div>
    </div>
  );
};

export default ProductsCarousel;
