import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import ButtonPlay from '../../../assets/svg/buttonPlay.svg';
import { Button } from '../Button';
import RichText from '../RichText';
import VideoModal from '../VideoModal';

const HeroCarouselItem = (props) => {
  const {
    title,
    bodyText,
    backgroundImageDesktop,
    backgroundImageMobile,
    video,
    videoPlaceholder,
    ctaText,
    ctaUrl,
  } = props;

  const hasVideo = video?.file && video?.file.contentType.includes('video');
  const hasLink = ctaUrl && ctaText;

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const imageSrc = [
    backgroundImageMobile?.fluid,
    { ...backgroundImageDesktop?.fluid, media: '(min-width: 1024px)' },
  ];

  return (
    <div className="hero-carousel-item">
      <div className="hero-carousel-item__image">
        <Image loading={'eager'} fadeIn={false} fluid={imageSrc} />
      </div>
      <div className="hero-carousel-item__content">
        <div className="hero-carousel-item__content-inner">
          <h1 className="hero-carousel-item__title">{title}</h1>
          <div className="hero-carousel-item__body">
            <RichText source={bodyText.json} />
          </div>
          <div className="hero-carousel-item__cta">
            {hasVideo && !hasLink && (
              <Button
                theme={'dark'}
                onClick={() => setShowModal(true)}
                className={`nav-button hero-carousel-item__button`}
              >
                <ButtonPlay className="hero-carousel-item__button-icon" />
                {ctaText}
              </Button>
            )}

            {hasLink && !hasVideo && (
              <Button
                theme={'dark'}
                href={ctaUrl}
                className={`nav-button hero-carousel-item__button`}
              >
                {ctaText}
              </Button>
            )}
          </div>
        </div>
      </div>

      <VideoModal
        onRequestClose={closeModal}
        isOpen={showModal}
        videoSrc={hasVideo && video.file.url}
        videoPoster={backgroundImageDesktop.fluid.src}
      />
    </div>
  );
};

HeroCarouselItem.defaultProps = {
  video: null,
  videoPlaceholder: null,
  ctaUrl: null,
};
HeroCarouselItem.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.object.isRequired,
  backgroundImageMobile: PropTypes.object.isRequired,
  backgroundImageDesktop: PropTypes.object.isRequired,
  video: PropTypes.object,
  videoPlaceholder: PropTypes.object,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
};

export default HeroCarouselItem;
