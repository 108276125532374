import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';
import DeviceMediaItem from '../DeviceMediaItem';

const DeviceFrame = (props) => {
  const { deviceType, className, style, asset, fallbackImage } = props;

  const data = useStaticQuery(graphql`
    {
      tablet: file(relativePath: { eq: "tablet-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 473, quality: 80 ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      laptop: file(relativePath: { eq: "laptop-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 830, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "phone-background-xl.png" }) {
        childImageSharp {
          fluid(maxWidth: 610, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const deviceBackground = data[deviceType].childImageSharp.fluid;

  return (
    <div
      className={`device-frame device-frame--${deviceType} ${className}`}
      style={style}
    >
      <BackgroundImage
        fluid={deviceBackground}
        className={'device-frame__image-overlay'}
        fadeIn={false}
        style={{
          position: 'absolute',
        }}
      />

      <div className={'device-frame__media-container'}>
        <DeviceMediaItem asset={asset} poster={fallbackImage} />
      </div>
    </div>
  );
};

DeviceFrame.propTypes = {
  deviceType: PropTypes.oneOf(['laptop', 'tablet', 'mobile']),
};
DeviceFrame.defaultProps = {
  deviceType: 'tablet',
  className: '',
  style: {},
};

export default DeviceFrame;
