import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DeviceFrame from '../DeviceFrame';
import SectionIntro from '../SectionIntro';
import Image from 'gatsby-image';
import RichText from '../RichText';
import { Button, ButtonContainer } from '../Button';

import isMobile from '../../utils/isMobile';

const ImageComp = (props) => {
  const {
    title,
    id,
    subheaderText,
    body,
    layoutVariant,
    deviceType,
    barTheme,
    ctaText,
    ctaLink,
    backgroundTheme,
    fallbackImage,
    asset,
  } = props;

  const hasIntro = title && subheaderText;

  const sectionRef = useRef();
  const barRef = useRef();
  const contentRef = useRef();
  const introRef = useRef();
  const deviceRef = useRef();

  const isMobileLayout = isMobile();

  const hasCTA = ctaText && ctaLink;
  
  const [animation, setAnimation] = useState(null);

  return (
    <div
      id={id}
      ref={sectionRef}
      className={`image-comp image-comp--${layoutVariant} image-comp--${deviceType} image-comp--bar-${barTheme} image-comp--background-${backgroundTheme} image-comp--${
        hasIntro ? 'withIntro' : 'noIntro'
      }`}
    >
      {hasIntro && (
        <div ref={introRef} className="image-comp__intro">
          <SectionIntro title={title} subtitle={subheaderText} />
        </div>
      )}

      <div className="image-comp__inner">
        <div className="image-comp__device">
          <div ref={deviceRef} className="image-comp__device-inner">
            <Image fluid={asset.fluid} />
          </div>
        </div>
        <div ref={contentRef} className="image-comp__content">
          <div className="image-comp__content-inner">
            {body?.json && <RichText source={body.json} />}
            {hasCTA && (
              <ButtonContainer align="left">
                <Button href={ctaLink} className="image-comp__cta">
                  {ctaText}
                </Button>
              </ButtonContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ImageComp.propTypes = {
  layoutVariant: PropTypes.oneOf(['deviceLeft', 'deviceRight']),
  deviceType: PropTypes.oneOf(['laptop', 'tablet', 'mobile']),
  backgroundTheme: PropTypes.oneOf(['default', 'white', 'grey']),
  barTheme: PropTypes.oneOf([
    'default',
    'transparent',
    'yellow',
    'orange',
    'dark-grey',
  ]),
};

ImageComp.defaultProps = {
  layoutVariant: 'deviceLeft',
  deviceType: 'laptop',
  asset: null,
  backgroundTheme: 'default',
  barTheme: 'default',
};

export default ImageComp;
