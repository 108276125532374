import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import { deviceDataFromDeviceAsset } from '../utils/contentfulHelpers';
import AlertBar from '../components/AlertBar/AlertBar';
import DeviceSection from '../components/DeviceSection';
import ImageComp from '../components/ImageComp';
import DemoBanner from '../components/DemoBanner';
// import Testimonial from '../components/Testimonial/Testimonial';
import CalloutsSection from '../components/CalloutsSection/CalloutsSection';
import PartnersSection from '../components/PartnersSection/PartnersSection';
import NewsletterSection from '../components/NewsletterSection/NewsletterSection';
import ResourcesSection from '../components/ResourcesSection/ResourcesSection';
import SiteMeta from '../components/SiteMeta';
import HeroCarousel from '../components/HeroCarousel/HeroCarousel';
import ProductsSection from '../components/ProductsSection';
// import {Helmet} from "react-helmet";

const Home = ({ data }) => {
  const { contentfulStaticHomePage: {
    metaTitle,
    metaDescription,
    alertBar,
    heroCarousel,
    featuresSection,
    transformationModule,
    demoBanner,
    testimonial,
    partnersSection,
    newsletterSection,
    resourcesSection,
    otherPwCProductsTitle,
    otherPwCProductsSubheader: { otherPwCProductsSubheader },
    otherPwCProducts,
  } } = data;
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      {/* <AlertBar {...alertBar} /> */}
      <HeroCarousel items={heroCarousel} />
      <ImageComp id="value-prop" barTheme="orange" layoutVariant="deviceRight" {...transformationModule} {...deviceDataFromDeviceAsset(transformationModule.deviceAsset)} />
      
      {/* <ResourcesSection {...resourcesSection} /> */}
      <ProductsSection title={otherPwCProductsTitle} subtitle={otherPwCProductsSubheader} items={otherPwCProducts} theme="dark" />
      {/* <DemoBanner {...demoBanner} theme="yellow" /> */}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    contentfulStaticHomePage(slug: {eq: "homepage-wfe"}) {
      metaTitle
      metaDescription
      alertBar {
        ...AlertBar
      }
      heroCarousel {
        ...HeroCarouselItem
      }
      partnersSection {
        ...PartnersSection
      }
      featuresSection {
        ...CalloutSection
      }
      transformationModule {
        ...DeviceNavigator
      }
      demoBanner {
        ...DemoBanner
      }
      testimonial {
        ...Testimonial
      }
      newsletterSection {
        ...NewsletterSection
      }
      resourcesSection {
        ...ResourcesSection
      }
      otherPwCProductsTitle
      otherPwCProductsSubheader {
        otherPwCProductsSubheader
      }
      otherPwCProducts {
        ctaText
        cardName
        ctaLink
        subheaderText
        image {
          fluid(quality: 80, toFormat: JPG) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
