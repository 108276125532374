import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import HeroCarouselItem from './HeroCarouselItem';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Arrow = () => {
  return (
    <svg
      width="29"
      height="51"
      viewBox="0 0 29 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 49L26 25.5L2 2" stroke="#FFB600" strokeWidth="4" />
    </svg>
  );
};

const HeroCarousel = ({ items }) => {
  let carouselOptions = {
    totalSlides: items.length,
    infinite: true,
    isIntrinsicHeight: true,
    dragEnabled: false
  };
  const autoRotateOptions = {
    interval: 8000,
    isPlaying: false,
    playDirection: 'forward'
  }

  const hasMultipleItems = items.length > 1;

  if (hasMultipleItems) {
    carouselOptions = { ...carouselOptions, ...autoRotateOptions }
  }

  return (
    <section className={`hero-carousel ${hasMultipleItems ? 'hero-carousel__multiple-items' : 'hero-carousel__single-item'}`}>
      <div className="hero-carousel__container">
        <CarouselProvider {...carouselOptions}>
          <div className="carousel-frame">
            <Slider>
              {items.map((item, i) => {
                return (
                  <Slide index={i} key={item.title}>
                    <HeroCarouselItem {...item} />
                  </Slide>
                );
              })}
            </Slider>
            {hasMultipleItems && (
              <>
                <ButtonBack>
                  <Arrow />
                </ButtonBack>
                <ButtonNext>
                  <Arrow />
                </ButtonNext>
                <DotGroup className="hero-carousel__dots" />
              </>
            )}
          </div>
        </CarouselProvider>
      </div>
    </section>
  );
};

HeroCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(HeroCarouselItem.propTypes)),
};

export default HeroCarousel;
