import React, { useRef, useState, useEffect } from 'react';
import Image from 'gatsby-image';
import { useIntersection } from 'react-use';

const VideoPlayer = ({poster, src}) => {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const [videoPoster, setVideoPoster] = useState('');
  const intersection = useIntersection(videoRef, {
      threshold: 0.0,
    });

    // To prevent loading videos before we need them, only insert the src once it's intesected.
    useEffect(() => {
      const isIntersecting = intersection && intersection.isIntersecting;
      if (isIntersecting && videoSrc !== src && videoPoster !== poster) {
        setVideoSrc(src)
        setVideoPoster(poster)
      }

    }, [intersection?.isIntersecting]);

    // LinkedIn _pauses_ videos, so we don't even see the posterframe in the <video>. To solve this, we position an image over the video if the video isn't playing.
  return (
    <div
      ref={videoRef}
      className={`device-video-player ${isPlaying ? 'is-playing' : 'not-playing'}`}
    >
      <div className="device-video-player__poster">
        {!isPlaying && <Image fluid={poster.fluid} />}
      </div>
      <video
        className="device-video-player__video"
        onPlaying={() => setIsPlaying(true)}
        muted
        loop
        autoPlay
        playsInline
        poster={videoPoster?.fluid?.src}
        src={videoSrc}
      />
    </div>
  );
};
const DeviceMediaItem = ({ asset, poster, className = '' }) => {
  const isImage = asset.file.contentType.includes('image');
  const isVideo = asset.file.contentType.includes('video');

  if (isVideo && poster === null) {
    console.error('Missing poster:', asset);
    throw new Error('All videos must have poster frames assigned in the CMS.')
  }

  if (isVideo)
    return <VideoPlayer poster={poster} className={className} src={asset.file.url} />;
  if (isImage)
    return (
      <Image
        className={className}
        backgroundColor={true}
        fluid={asset.fluid}
        alt={asset.description}
      />
    );
  return null;
};

DeviceMediaItem.defaultProps = {
  poster: null
}

export default DeviceMediaItem;
