import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import Link from '../Link';

const BAR_SEVERITY_OPTIONS = ['critical', 'major', 'minor'];

const AlertBar = ({ text, barLabel, ctaLabel, ctaUrl, severity }) => {
  const [barVisible, setBarVisible] = useState(true);
  const hasLink = ctaLabel && ctaUrl;
  return (
    <div
      className={`alert-bar alert-bar--${
        barVisible ? `visible` : `hidden`
      } alert-bar--${severity.toLowerCase()}`}
    >
      <div>
        <div className="alert-bar__label">{barLabel}</div>
        <p className="alert-bar__text">
          <span>{text}</span>
          {hasLink && (
            <>
              {' '}
              <Link href={ctaUrl}>{ctaLabel}</Link>
            </>
          )}
        </p>
        <button
          onClick={() => setBarVisible(false)}
          type="button"
          aria-label="Close Alert Bar"
          className="alert-bar__close"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.000326872 17.6777L17.678 1.75834e-05L19.7993 2.12134L2.12165 19.799L0.000326872 17.6777Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.12139 -0.000162721L19.7991 17.6775L17.6777 19.7988L6.6638e-05 2.12116L2.12139 -0.000162721Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

AlertBar.propTypes = {
  text: PropTypes.string.isRequired,
  barLabel: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(BAR_SEVERITY_OPTIONS).isRequired,
};

export default AlertBar;
